<template>
    <div class="setting-website">
        <div class="form-container">
            <div class="form-body">

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>网站LOGO(254*84)</span>
                            <em>*</em>
                        </div>
                    </div>
                    <div class="item">
                        <n-upload class="upload-box" v-model:src="formData.logo" :width="254" :height="84" />
                    </div>
                </div>

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>网站标题</span>
                            <em>*</em>
                        </div>
                    </div>
                    <div class="item">
                        <n-input placeholder="请输入网站标题" v-model:value="formData.title" />
                    </div>
                </div>

                <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>关键字</span>
                            </div>
                        </div>
                        <div class="item keywords">
                            <n-tag 
                                class="tag"
                                theme="fill" type="primary"
                                v-for="(item, index) in formData.keywords" :key="index"
                                @close="removeKeyword(index)"
                            >
                                {{ item }}
                            </n-tag>
                            <n-input
                                class="keyword-input"
                                v-model:value="keyword"
                                @enter="addKeyword"
                                placeholder="输入关键字"
                            />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>描述</span>
                            </div>
                        </div>
                        <div class="item">
                            <n-textarea 
                                v-model:value="formData.description"
                                :maxlength="240"
                            ></n-textarea>
                        </div>
                    </div>

            </div>
        </div>

        <div class="bottom-tools-box">
            <n-button class="tool-btn" size="large" @click="location.reload()">重置</n-button>
            <n-button 
                class="tool-btn" 
                size="large" 
                type="primary" 
                :loading="btnLoading"
                :disabled="formData.title === '' || formData.logo === ''"
                @click="$emit('submit', formData)"
            >确定</n-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SettingWebsite',
    props: {
        data:{
            type: Object,
            default: {}
        },
        btnLoading: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            formData:{
                title: '',
                logo: '',
                keywords: [],
                description: ''
            },
            keyword: ''
        }
    },
    emits: ['submit'],
    watch: {
        'data':{
            handler(){
                if(this.data.title) this.formData = this.data;
            },
            deep: true
        }
    },
    mounted(){
        if(this.data.title) this.formData = this.data;
    },
    methods:{
        addKeyword(){
            this.formData.keywords.push(this.keyword);
            this.keyword = ''
        },
    }
}
</script>

<style lang="scss" scoped>
@import 'form-style';
.setting-website{
    margin: 15px;
    .form-container{
        margin: 0;
        .upload-box{
            background-color: $page-color;
        }
    }
}
</style>